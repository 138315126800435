const Description = ({ children }) => {
  return <div className="project-description">{children}</div>;
};

/**
 * images should be of ratio 5:7 or 500px x 700px
 */
const projects = [
  {
    id: 0,
    name: "salazarsweets",
    title: "SalazarSweets.com",
    repoUrl: null,
    projectUrl: "https://salazarsweets.com",
    images: [
      "assets/salazarsweets-screencaps/salazarsweets-0.png",
      "assets/salazarsweets-screencaps/salazarsweets-1.png",
      "assets/salazarsweets-screencaps/salazarsweets-2.png",
      "assets/salazarsweets-screencaps/salazarsweets-3.png",
      // "assets/salazarsweets-screencaps/salazarsweets-4.png",
    ],
    description: (
      <Description>
        <ul>
          <li>
            A website for a local baking business that connects multiple APIs to
            build an engaging and user-friendly experience.
          </li>
          <li>
            Utilizes the <strong>Google Calendar API</strong> for displaying
            scheduled business events, and the{" "}
            <strong>Instagram Basic Display API</strong> for showcasing recent
            posts in a photo gallery.
          </li>
          <li>
            Implements an <strong>email client</strong> for securely accepting
            online business inquiries.
          </li>
        </ul>
      </Description>
    ),
  },
  {
    id: 3,
    name: "animalcrossing",
    title: "<em>Animal Crossing</em> Web App",
    repoUrl: "https://github.com/nickolasalcazar/AnimalCrossingWiki",
    projectUrl: "https://critter-catalog.onrender.com/",
    images: [
      "assets/cc-screencaps/cc-4.png",
      "assets/cc-screencaps/cc-3.png",
      "assets/cc-screencaps/cc-5.png",
      "assets/cc-screencaps/cc-1.png",
    ],
    description: (
      <Description>
        <ul>
          <li>
            A <strong>React</strong> app that catalogs the items and characters
            featured in the Nintendo video game <em>Animal Crossing</em>.
          </li>
          <li>
            Utilizes a public <strong>RESTful API</strong> for querying assets,
            and presents them in a <strong>React.js</strong> frontend.
          </li>
          <li>
            Designed and implemented various responsive UI components, such as
            pagination, modals, and navigation bars.
          </li>
          <li>
            Developed a highly stylized frontend that showcases advanced{" "}
            <strong>CSS</strong> skills and attention to detail in design.
          </li>
        </ul>
      </Description>
    ),
  },
  {
    id: 1,
    name: "boggle",
    title: "Self-Solving <em>Boggle</em>",
    repoUrl: "https://github.com/nickolasalcazar/Boggle",
    projectUrl: "https://nickolasalcazar.github.io/Boggle/",
    images: [
      "assets/boggle-screencaps/boggle-0.png",
      "assets/boggle-screencaps/boggle-1.png",
    ],
    description: (
      <Description>
        <ul>
          <li>
            A <strong>frontend</strong> recreation of the classic board game{" "}
            <em>Boggle</em> using HTML, CSS, and <strong>JavaScript</strong>.
          </li>
          <li>
            Implemented a self-solving feature that displays all possible word
            formations of the current game board.
          </li>
          <li>
            Implemented a prefix tree data structure for efficient{" "}
            <em style={{ fontFamily: "serif" }}>O(n)</em> lookup of words in a
            dictionary.
          </li>
        </ul>
      </Description>
    ),
  },
  {
    id: 2,
    name: "checkers",
    title: "Online Checkers",
    repoUrl: "https://github.com/nickolasalcazar/OnlineCheckers",
    projectUrl: "https://play-checkers-online.herokuapp.com/",
    images: [
      "assets/checkers-screencaps/checkers-0.png",
      "assets/checkers-screencaps/checkers-1.png",
      "assets/checkers-screencaps/checkers-2.png",
      "assets/checkers-screencaps/checkers-3.png",
    ],
    description: (
      <Description>
        <ul>
          <li>
            An online recreation of Checkers that allows two players to connect
            and play against each other using a unique game code.
          </li>
          <li>
            Written using <strong>Node.js</strong>, <strong>Express</strong>,
            and <strong>WebSockets</strong> to enable real-time gameplay between
            players.
          </li>
        </ul>
      </Description>
    ),
  },
  {
    id: 4,
    name: "secretsanta",
    title: "Secret Santa Web App",
    repoUrl: "https://github.com/nickolasalcazar/SecretSanta",
    projectUrl: "http://secretsanta.nickolasalcazar.com/",
    images: [
      "assets/secretsanta-screencaps/secretsanta-0.png",
      "assets/secretsanta-screencaps/secretsanta-1.png",
      "assets/secretsanta-screencaps/secretsanta-2.png",
      "assets/secretsanta-screencaps/secretsanta-3.png",
    ],
    description: (
      <Description>
        <ul>
          <li>
            A web app that generates Secret Santa games for registered users.
          </li>
          <li>
            Utilizes the email delivery platform <strong>Mailgun</strong> to
            notify invited players to whom they are giving a gift.
          </li>
          <li>
            Users can create, save, update, and delete Secret Santa games that
            include a roster of participants.
          </li>
          <li>
            Developed using the <strong>Python</strong>-based web framework{" "}
            <strong>Django</strong>. Implements a login system.
          </li>
        </ul>
      </Description>
    ),
  },
];

export default projects;
