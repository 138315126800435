import "./About.css";

function About(props) {
  return (
    <article id="about">
      <h2>About Me</h2>
      <section>
        <img
          alt="Photo of Nick holding a painting"
          src="/assets/painting.jpg"
          title="I painted this outside!"
          data-aos="fade-up"
        />
        <div>
          <p data-aos="fade-up">
            I'm a software developer with a passion for tech and web apps. In my
            spare time, I enjoy exploring my creative side through painting and
            calligraphy.
          </p>
          <p data-aos="fade-up">
            In the very near future, I plan on building my own online art
            gallery to showcase my paintings (and maybe make some commissions)!
            Come back soon and have a look!
          </p>
          <p style={{ textAlign: "center" }} data-aos="fade-down">
            <span style={{ fontSize: "48pt", lineHeight: "64pt" }}>👨🏽‍🎨</span>
          </p>
          <p data-aos="fade-up">
            When I'm not coding or making art, I like to stay active through
            exercise and mountain climbing. I'm also a big fan of sandbox video
            games, such as <em>Cities: Skylines</em> and <em>Minecraft</em>.
          </p>
          <p data-aos="fade-up">Thanks for scrolling this far!</p>
        </div>
      </section>
    </article>
  );
}

export default About;
