import "./ScrollUpBtn.css";

function ScrollUpBtn() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <button
      type="button"
      title="Scroll back to top"
      alt="Scroll back to top"
      className="scroll-btn"
      onClick={scrollToTop}
    >
      <i
        className="fa fa-solid fa-circle-arrow-up"
        data-aos="zoom-in-up"
        data-aos-duration="500"
        data-aos-once="false"
        data-aos-mirror="true"
      ></i>
    </button>
  );
}

export default ScrollUpBtn;
