import { useContext, useEffect, useRef } from "react";
import { HashLink } from "react-router-hash-link";

import NickLogo from "../NickLogo/NickLogo";

import { ThemeContext } from "../../App";

import "./navbar.css";

const Navbar = (props) => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  const onScroll = () => {
    let winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    let height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    let scrolled = (winScroll / height) * 100;
    document.getElementById("scroll-progress-bar").style.width = scrolled + "%";
  };

  useEffect(() => {
    const darkmodeSwitch = document.querySelector(
      "#darkmode-switch-container input"
    );
    theme === "dark"
      ? (darkmodeSwitch.checked = true)
      : (darkmodeSwitch.checked = false);

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <nav>
      <NickLogo color="var(--logo-color)" />
      <ul id="navbar-menu" className="no-highlight">
        <div id="scroll-progress-bar-container">
          <div id="scroll-progress-bar" aria-hidden="true"></div>
        </div>
        <li id="nav-home">
          <HashLink smooth to="#" onClick={scrollToTop}>
            <span>home</span>
          </HashLink>
        </li>
        <li id="nav-projects">
          <HashLink smooth to="#projects">
            <span>projects</span>
          </HashLink>
        </li>
        {/* <li id="nav-resume">
          <HashLink smooth to="#resume">
            <span>resume</span>
          </HashLink>
        </li> */}
        <li id="nav-about">
          <HashLink smooth to="/#about">
            <span>about</span>
          </HashLink>
        </li>
      </ul>
      <label id="darkmode-switch-container" title="Toggle dark mode">
        <input type="checkbox" onChange={toggleTheme} />
        <span className="darkmode-slider round"></span>
      </label>
    </nav>
  );
};

export default Navbar;
