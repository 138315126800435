import { useEffect } from "react";
import "./Home.css";

import Projects from "../../components/Projects/Projects";
import Resume from "../../components/Resume/Resume";
import About from "../../components/About/About";
import ScrollUpBtn from "../../components/UI/ScrollUpBtn/ScrollUpBtn";

import { HashLink } from "react-router-hash-link";

// Component fade-in animation library
import AOS from "aos";
import "aos/dist/aos.css";

function Home(props) {
  useEffect(() => {
    // AOS will work in all children
    AOS.init({
      duration: 1250,
      once: true,
    });
  }, []);

  return (
    <>
      <header>
        <div className="header-inner" data-aos="fade-up">
          <img
            src="/assets/profile-pic-portrait-low-res.jpg"
            // src="/assets/profile-pic-portrait.png"
            className="box-shadow no-highlight"
            title="Me at Bass Lake in 2021"
          />
          <div className="header-titles">
            <h1 className="text-outline">Nickolas Alcazar</h1>
            <h2>Web Developer</h2>
          </div>
          <div className="header-text">
            <p>
              I'm a recent Computer Science grad who enjoys developing web apps.
              I have experience deploying websites and mobile apps that use
              JavaScript and Python frameworks.
            </p>
            <HashLink
              smooth
              to="#projects"
              className="see-projects-btn box-shadow"
            >
              View Projects
            </HashLink>
          </div>
        </div>
      </header>
      <main>
        <Projects />
        {/* <Resume /> */}
        <About />
      </main>
      <footer>
        <ScrollUpBtn />
        <ul>
          <li>
            <a
              title="@NickolasAlcazar on GitHub"
              target="_blank"
              alt="Link to GitHub profile"
              href="https://github.com/nickolasalcazar"
            >
              <i className="fa fa-brands fa-github"></i>
            </a>
          </li>
          <li>
            <a
              title="LinkedIn"
              target="_blank"
              alt="LinkedIn"
              href="https://www.linkedin.com/in/nickolasalcazar/"
            >
              <i className="fa fa-brands fa-linkedin-in"></i>
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
}

export default Home;
