import projects from "./projects-data";
import ProjectCard from "../ProjectCard/ProjectCard";

import "./Projects.css";

function Projects() {
  return (
    <article id="projects">
      <h2>Projects</h2>
      <div id="project-cards-container" className="margin-auto">
        {projects.map((project) => (
          <ProjectCard project={project} key={`${project.name}-card`} />
        ))}
      </div>
    </article>
  );
}

export default Projects;
