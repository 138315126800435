import "./ProjectCard.css";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ProjectCard = ({ project }) => {
  const { title, repoUrl, projectUrl, images, description } = project;

  return (
    <section className="project-card" data-aos="fade-up">
      <h3 dangerouslySetInnerHTML={{ __html: title }} />
      {images ? (
        <Carousel
          autoPlay={true}
          dynamicHeight={true}
          emulateTouch={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          useKeyboardArrows={false}
          renderArrowPrev={(clickHandler, hasPrev, label) => {
            return (
              <span className="carousel-arrow-left" onClick={clickHandler}>
                <i className="fa fa-solid fa-circle-chevron-left"></i>
              </span>
            );
          }}
          renderArrowNext={(clickHandler, hasNext, label) => {
            return (
              <span className="carousel-arrow-right" onClick={clickHandler}>
                <i className="fa fa-solid fa-circle-chevron-right"></i>
              </span>
            );
          }}
        >
          {images.map((image, index) => (
            <div key={`${project.name}-img-${index}`}>
              <img src={image} className="no-highlight" />
            </div>
          ))}
        </Carousel>
      ) : (
        <img src="assets/placeholder-img.png" />
      )}
      {description}
      <div className="project-links-container">
        {projectUrl && (
          <a
            title={`Open project in a new tab`}
            target="_blank"
            alt={`Open project in a new tab`}
            href={projectUrl}
            className="project-card-view-project-link"
          >
            <span>View project</span>
            <i className="fa fa-solid fa-arrow-up-right-from-square"></i>
          </a>
        )}
        {repoUrl && (
          <a
            title="Open Github repo in a new tab"
            target="_blank"
            alt="Open Github repo in a new tab"
            href={repoUrl}
            className="project-card-github-link"
          >
            {/* <span>GitHub repo</span> */}
            <img
              className="project-card-github-logo"
              src="/assets/GitHub_Logo.png"
            ></img>
            <i className="fa fa-brands fa-github"></i>
          </a>
        )}
      </div>
    </section>
  );
};

export default ProjectCard;
